body {
    background-color: white;
    font-family: Roboto;
  }
  
  .Navbar {
    background-color: #314d7f;
    position: fixed;
    top: 0;
    left: auto;
    right: 0;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: bold;
    font-family: Roboto;
    width: 100%;
    z-index: 1000;
    box-shadow: 0px 0px 5px silver;
  }
  
  .Toolbar {
    display: flex;
    min-height: 56px;
    align-items: center;
    padding: 0px 10px;
  }
  
  .Logo {
    margin-right: 10px;
  }
  
  .Title {
    flex-grow: 1;
    color: whitesmoke;
  }
  